import { Button } from '@/components/ui/button';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import useActiveLink from '@/hooks/useActiveLink';
import { Mixpanel } from '@/utils/mixPanel';
import { LogOut } from 'lucide-react';
import Link from 'next/link';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerMenuT } from './DrawerMenu.types';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import useMixPanel from '@/hooks/useMixPanel';

const DrawerMenu = ({ routes, open, setOpen }: DrawerMenuT) => {
  const Mixpanel = useMixPanel();
  const { routeIsActive } = useActiveLink();
  const { t } = useTranslation('navigation');
  const { user } = useUser();

  return (
    <Drawer direction="bottom" open={open} onOpenChange={(e) => setOpen(e)}>
      <DrawerContent>
        <div className="flex space-x-2 pl-4 py-2 ">
          <Avatar className="w-8 h-8 flex">
            <AvatarImage src={user?.picture ?? ''} alt={user?.name ?? ''} />
            <AvatarFallback>{user?.name?.[0]}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {`${user?.name}`}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </div>

        {routes.map((route, position) => (
          <Fragment key={route.title}>
            <Separator
              className={` ${position === 0 ? 'bg-transparent' : ' '}`}
            />

            <Link
              href={route?.href ? route.href : ''}
              className={`w-full flex flex-row items-center  ${
                routeIsActive(route) ? 'bg-primary/30' : ' '
              }`}
            >
              <Button
                onClick={() => {
                  Mixpanel.track('press burguer menu ' + route.title, {
                    screen: 'nav bar mobile'
                  });
                  setOpen(false);
                }}
                variant={'ghost'}
                className="gap-2 h-14  pl-4 w-full justify-start"
              >
                {route.icon} {route.title}
              </Button>
            </Link>
          </Fragment>
        ))}
        <Separator />

        <Button
          onClick={() => {
            Mixpanel.track('press burguer menu logout', {
              screen: 'nav bar mobile'
            });
            setOpen(false);
          }}
          variant={'ghost'}
          className="gap-2 h-14  pl-4 w-full justify-start"
          asChild
        >
          <a href="/api/auth/logout">
            <LogOut /> {t('Session.Logout')}
          </a>
        </Button>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerMenu;
